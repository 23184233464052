.loading {
  margin: 35px auto;
}
.loading span {
  -moz-animation: 3s infinite linear;
  -o-animation: 3s infinite linear;
  -webkit-animation: 3s infinite linear;
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  width: 16px;
}
.loading span:nth-child(2) {
  -moz-animation: kiri 1.2s infinite linear;
  -o-animation: kiri 1.2s infinite linear;
  -webkit-animation: kiri 1.2s infinite linear;
  background: #E84C3D;
}
.loading span:nth-child(3) {
  background: #F1C40F;
  z-index: 100;
}
.loading span:nth-child(4) {
  -moz-animation: kanan 1.2s infinite linear;
  -o-animation: kanan 1.2s infinite linear;
  -webkit-animation: kanan 1.2s infinite linear;
  background: #2FCC71;
}
.loading h1 {
  color: #333;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  text-align: center;
}
@-webkit-keyframes kanan {
  0% {
    -webkit-transform: translateX(20px);
  }
  50% {
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(20px);
    z-index: 200;
  }
}
@-moz-keyframes kanan {
  0% {
    -moz-transform: translateX(20px);
  }
  50% {
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(20px);
    z-index: 200;
  }
}
@-o-keyframes kanan {
  0% {
    -o-transform: translateX(20px);
  }
  50% {
    -o-transform: translateX(-20px);
  }
  100% {
    -o-transform: translateX(20px);
    z-index: 200;
  }
}
@-webkit-keyframes kiri {
  0% {
    -webkit-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(-20px);
  }
}
@-moz-keyframes kiri {
  0% {
    -moz-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(-20px);
  }
}
@-o-keyframes kiri {
  0% {
    -o-transform: translateX(-20px);
    z-index: 200;
  }
  50% {
    -o-transform: translateX(20px);
  }
  100% {
    -o-transform: translateX(-20px);
  }
}
