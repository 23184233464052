
.prettyDD {
    background: none;
    border: none;
    border-bottom: solid thin #606060;
    cursor: pointer;
}

.dashboardDiffDisplay {
    text-align: center;
    font-size: 24px;
}
.thumb {
    width: 24px;
    height: 24px;
    float: none;
    position: relative;
    top: 7px;
}
form .progress {
    line-height: 15px;
}
.progress {
    display: inline-block;
    width: 100px;
    border: 3px groove #CCC;
}
.progress div {
    font-size: smaller;
    background: orange;
    width: 0;
}

tr.highlight td {
    background-color: #b7ffbc;
}

.health {
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
}

.health-meter {
    width: calc(32%);
    border: 1px solid black;
    margin: 5px;
}

.health-meter-header {
    height: auto;
    width: 100%;
    border-bottom: 1px solid black;
    display: inline-flex;
}

.health-meter-title {
    padding: 0;
    margin: 5px;
    width: 80%;
}

.health-meter-indicator {
    width: 20px;
    height: 20px;
    float: right;
    border: 1px solid black;
    margin: 5px;
    border-radius: 50%;
}

.health-meter-indicator.finished {
    background-color: green;
}

.health-meter-indicator.running {
    background-color: yellow;
}

.health-meter-indicator.pending {
    background-color: red;
}

.health-meter-status {
    margin: 5px;
}
